export const routes = [{
        path: '/',
        name: 'layout',
        component: () => import('@/layout/Layout.vue'),
        redirect: '/user',
        children: [{
                path: '/user',
                name: 'userListAll',
                component: () => import('@/pages/userList/list/index.vue'),
                redirect: '/user/list',
                meta: {
                    title: '账号列表',
                    showBreadcrumb: true,
                },
                children: [{
                        path: '/user/list',
                        name: 'userList',
                        component: () => import('@/pages/userList/list/UserList.vue'),
                        meta: {
                            title: '账号列表',
                            keepAlive: true,
                            showBreadcrumb: false,
                            headerKey: 'operationCenterData',
                            menuKey: 'user',
                            activeKey: 'userList',
                        },
                    },
                    {
                        path: '/user/add',
                        name: 'addUser',
                        component: () => import('@/pages/userList/list/AddUser.vue'),
                        meta: {
                            title: '新增用户',
                            showBreadcrumb: true,
                            headerKey: 'operationCenterData',
                            menuKey: 'user',
                            activeKey: 'userList',
                        },
                    },
                    {
                        path: '/user/addPackage',
                        name: 'addPackage',
                        component: () => import('@/pages/userList/list/AddPackage.vue'),
                        meta: {
                            title: '新增服务',
                            showBreadcrumb: true,
                            headerKey: 'operationCenterData',
                            menuKey: 'user',
                            activeKey: "",
                        },
                    },
                    {
                        path: '/user/userDetail',
                        name: 'userDetail',
                        component: () => import('@/pages/userList/detail/UserDetail.vue'),
                        meta: {
                            title: '用户详情',
                            showBreadcrumb: true,
                            headerKey: 'operationCenterData',
                            menuKey: 'user',
                            activeKey: 'userList',
                        },
                    },
                    {
                        path:'/user/packageDetail',
                        name:'PackageDetail',
                        component: () => import('@/pages/userList/detail/PackageDetail.vue'),
                        meta: {
                            title: '用户服务详情',
                            showBreadcrumb: true,
                            headerKey: 'operationCenterData',
                            menuKey: 'user',
                            activeKey: 'userList',
                        },
                    }
                ]
            },
            {
                path: '/package',
                name: 'package',
                component: () => import('@/pages/userList/packageList/index.vue'),
                redirect: '/package/list',
                meta: {
                    title: '服务列表',
                    showBreadcrumb: true,
                },
                children: [{
                    path: '/package/list',
                    name: 'packageList',
                    component: () => import('@/pages/userList/packageList/list.vue'),
                    meta: {
                        title: '服务列表',
                        headerKey: 'operationCenterData',
                        menuKey: 'user',
                        activeKey: 'packageList',
                    },
                }]
            },
            {
                path: '/encrypt',
                name: 'encrypt',
                component: () => import('@/pages/encrypt/index'),
                redirect: '/encrypt/list',
                meta: {
                    title: '加密管理',
                    showBreadcrumb: true,
                    headerKey: 'operationCenterData',
                    menuKey: 'user',
                    activeKey: 'encryptList',
                },
                children: [{
                    path: '/encrypt/list',
                    name: 'encryptList',
                    component: () => import('@/pages/encrypt/encryptList'),
                    meta: {
                        title: '加密设备列表',
                        showBreadcrumb: true,
                        headerKey: 'operationCenterData',
                        menuKey: 'user',
                        activeKey: 'encryptList',
                    },
                }, {
                    path: '/encrypt/addDevice',
                    name: 'addDevice',
                    component: () => import('@/pages/encrypt/addDevice'),
                    meta: {
                        title: '添加设备',
                        showBreadcrumb: true,
                        headerKey: 'operationCenterData',
                        menuKey: 'user',
                        activeKey: 'encryptList',
                    },
                }]
            },
            {
                path: 'projectContro',
                name: 'projectControIndex',
                component: () => import('@/pages/projectControl/Index.vue'),
                redirect: '/projectContro/list',
                meta: {
                    title: '项目列表',
                    showBreadcrumb: true,
                },
                children: [{
                        path: '/projectContro/list',
                        name: 'projectControList',
                        component: () => import('@/pages/projectControl/ProjectControlList.vue'),
                        meta: {
                            title: '项目列表',
                            showBreadcrumb: false,
                            headerKey: 'operationCenterData',
                            menuKey: 'project',
                            activeKey: 'projectList',
                        },
                    },
                    {
                        path: '/projectContro/detail',
                        name: 'projectDetail',
                        component: () => import('@/pages/projectControl/ProjectDetail.vue'),
                        meta: {
                            title: '项目详情',
                            showBreadcrumb: true,
                            headerKey: 'operationCenterData',
                            menuKey: 'project',
                            activeKey: 'projectList',
                        },
                    }, 
                    {
                        path: '/projectContro/autoDetail',
                        name: 'autoProjectDetail',
                        component: () => import('@/pages/projectControl/AutoProjectDetail.vue'),
                        meta: {
                            title: 'AI找风资源项目详情',
                            showBreadcrumb: true,
                            headerKey: 'operationCenterData',
                            menuKey: 'project',
                            activeKey: 'projectList',
                        },
                    }, 
                    {
                        path: '/projectContro/tdMapResult',
                        name: 'tdMapResult',
                        component: () => import('@/pages/projectControl/projectResult/TdMapResult.vue'),
                        meta: {
                            title: 'AI找风资源项目预览',
                            showBreadcrumb: true,
                            headerKey: 'operationCenterData',
                            menuKey: 'project',
                            activeKey: 'projectList',
                        },
                    }, 
                    {
                        path: '/projectContro/proposalDetail',
                        name: 'projectProposalDetail',
                        component: () => import('@/pages/projectControl/ProjectProposalDetail.vue'),
                        meta: {
                            title: '项目建议书详情',
                            showBreadcrumb: true,
                            headerKey: 'operationCenterData',
                            menuKey: 'project',
                            activeKey: 'projectList',
                        },
                    }, 
                    {
                        path: '/projectContro/progress',
                        name: 'projectProgress',
                        component: () => import('@/pages/projectControl/ProjectProgress.vue'),
                        meta: {
                            title: '项目进度',
                            showBreadcrumb: true,
                            headerKey: 'operationCenterData',
                            menuKey: 'project',
                            activeKey: 'projectList',
                        },
                    }
                ]
            },
            {
                path: 'clue',
                name: 'clueIndex',
                component: () => import('@/pages/clue/Index.vue'),
                redirect: '/clue/list',
                meta: {
                    title: '线索管理',
                    showBreadcrumb: true,
                },
                children:[
                    {
                        path: '/clue/list',
                        name: 'clueList',
                        component: () => import('@/pages/clue/ClueList'),
                        meta: {
                            title: '线索列表',
                            showBreadcrumb: true,
                            headerKey: 'operationCenterData',
                            menuKey: 'clue',
                            activeKey: 'cluetList',
                        },
                    }, 
                    {
                        path: '/clue/add',
                        name: 'addClue',
                        component: () => import('@/pages/clue/AddClue'),
                        meta: {
                            title: '新增线索',
                            showBreadcrumb: true,
                            headerKey: 'operationCenterData',
                            menuKey: 'clue',
                            activeKey: 'cluetList',
                        },
                    }, 
                ]
            },
            {
                path: '/tagList',
                name: 'tagList',
                component: () => import('@/pages/dataService/tagManagement/index.vue'),
                meta: {
                    title: '标签管理',
                    showBreadcrumb: true,
                    headerKey: 'dataServiceData',
                    menuKey: 'projectTemplateManage',
                    activeKey: 'tagManage',
                },
            }, {
                path: '/argumentList',
                name: 'argumentList',
                component: () => import('@/pages/dataService/argumentManagement/index.vue'),
                meta: {
                    title: '参数管理',
                    showBreadcrumb: true,
                    headerKey: 'dataServiceData',
                    menuKey: 'projectTemplateManage',
                    activeKey: 'argumentManage',
                },
            },
            {
                path: '/geology',
                name: 'geology',
                redirect: '/geology/list',
                component: (a) => import('@/pages/commonDataManage/geologyData/index.vue'),
                meta: {
                    title: '地质数据库',
                    showBreadcrumb: true,
                },
                children: [{
                    path: '/geology/list',
                    name: 'geologyList',
                    component: () => import('@/pages/commonDataManage/geologyData/geologyHome.vue'),
                    meta: {
                        title: '地质数据库',
                        showBreadcrumb: true,
                        headerKey: 'dataServiceData',
                        menuKey: 'commonDataManage',
                        activeKey: 'geologicalData',
                        keepAlive: true
                    },
                }, {
                    path: '/geology/detail',
                    name: 'geologyDetail',
                    component: () => import('@/pages/commonDataManage/geologyData/geologyDetail.vue'),
                    meta: {
                        title: '地质数据库数据详情',
                        showBreadcrumb: true,
                        headerKey: 'dataServiceData',
                        menuKey: 'commonDataManage',
                        activeKey: 'geologicalData',
                    },
                }, {
                    path: '/geology/edit',
                    name: 'geologyEdit',
                    component: () => import('@/pages/commonDataManage/geologyData/geologyEdit.vue'),
                    meta: {
                        title: '地质数据库数据管理',
                        showBreadcrumb: true,
                        headerKey: 'dataServiceData',
                        menuKey: 'commonDataManage',
                        activeKey: 'geologicalData',
                    },
                }, {
                    path: '/geology/linkage',
                    name: 'geologyLinkage',
                    component: () => import('@/pages/commonDataManage/geologyData/geologyLinkage.vue'),
                    meta: {
                        title: '项目关联信息',
                        showBreadcrumb: true,
                        headerKey: 'dataServiceData',
                        menuKey: 'commonDataManage',
                        activeKey: 'geologicalData',
                    },
                }, {
                    path: '/geology/map',
                    name: 'geologyMap',
                    component: () => import('@/pages/commonDataManage/geologyData/geologyMap.vue'),
                    meta: {
                        title: '地质数据管理',
                        showBreadcrumb: true,
                        headerKey: 'dataServiceData',
                        menuKey: 'commonDataManage',
                        activeKey: 'geologicalData',
                    },
                }, {
                    path: '/geology/geography',
                    name: 'geographyManage',
                    component: () => import('@/pages/commonDataManage/geologyData/geographyManage.vue'),
                    meta: {
                        title: '地形数据管理',
                        showBreadcrumb: true,
                        headerKey: 'dataServiceData',
                        menuKey: 'commonDataManage',
                        activeKey: 'geologicalData',
                    },
                }]
            },
            {
                path: '/currentPower',
                name: 'currentPowerAll',
                component: () => import('@/pages/commonDataManage/currentPower/Index.vue'),
                redirect: '/currentPower/list',
                meta: {
                    title: '电网现状数据库',
                    showBreadcrumb: true,
                },
                children: [{
                        path: '/currentPower/list',
                        // name: 'currentPower',
                        component: () => import('@/pages/commonDataManage/currentPower/CurrentPower.vue'),
                        meta: {
                            title: '电网现状数据库',
                            showBreadcrumb: true,
                            headerKey: 'dataServiceData',
                            menuKey: 'commonDataManage',
                            activeKey: 'gridCurrentData',
                            keepAlive: true
                        },
                    },
                    {
                        path: '/currentPower/add',
                        name: 'addCurrentPower',
                        component: () => import('@/pages/commonDataManage/currentPower/AddCurrentPower.vue'),
                        meta: {
                            title: '电网现状数据管理',
                            showBreadcrumb: true,
                            headerKey: 'dataServiceData',
                            menuKey: 'commonDataManage',
                            activeKey: 'gridCurrentData',
                        }
                    },
                    {
                        path: '/currentPower/detail',
                        name: 'currentPowerDetail',
                        component: () => import('@/pages/commonDataManage/currentPower/CurrentPowerDetail.vue'),
                        meta: {
                            title: '电网数据详情',
                            showBreadcrumb: true,
                            headerKey: 'dataServiceData',
                            menuKey: 'commonDataManage',
                            activeKey: 'gridCurrentData',
                        }
                    }
                ]
            },
            {
                path: '/windTower',
                name: 'windTower',
                component: () => import('@/pages/commonDataManage/windTowerDatabase/Index.vue'),
                redirect: '/windTower/list',
                meta: {
                    title: '测风塔数据库',
                    showBreadcrumb: true,
                },
                children: [{
                        path: '/windTower/list',
                        name: 'windTowerList',
                        component: () => import('@/pages/commonDataManage/windTowerDatabase/windTowerList.vue'),
                        meta: {
                            title: '测风塔数据库列表',
                            showBreadcrumb: true,
                            headerKey: 'dataServiceData',
                            menuKey: 'commonDataManage',
                            activeKey: 'windTowerData',
                        },
                    },
                    {
                        path: '/windTower/detail',
                        name: 'windTowerDetail',
                        component: () => import('@/pages/commonDataManage/windTowerDatabase/windTowerDetail.vue'),
                        meta: {
                            title: '测风塔数据库详情',
                            showBreadcrumb: true,
                            headerKey: 'dataServiceData',
                            menuKey: 'commonDataManage',
                            activeKey: 'windTowerData',
                        },
                    },
                ]
            },
            {
                path: '/windEquipment',
                name: 'windEquipment',
                component: () => import('@/pages/commonDataManage/windPowerEquipment/Index.vue'),
                redirect: '/windEquipment/list',
                meta: {
                    title: '风电设备库',
                    showBreadcrumb: true,
                },
                children: [
                    {
                        path: '/windEquipment/list',
                        name: 'windEquipmentList',
                        component: () => import('@/pages/commonDataManage/windPowerEquipment/WindPowerEquipmentList.vue'),
                        meta: {
                            title: '风电设备库',
                            showBreadcrumb: true,
                            headerKey: 'dataServiceData',
                            menuKey: 'commonDataManage',
                            activeKey: 'windTurbineData',
                        },
                    },
                    {
                        path: '/windEquipment/detail',
                        name: 'windEquipmentDetail',
                        component: () => import('@/pages/commonDataManage/windPowerEquipment/detail/Detail.vue'),
                        meta: {
                            title: '设备详情',
                            showBreadcrumb: true,
                            headerKey: 'dataServiceData',
                            menuKey: 'commonDataManage',
                            activeKey: 'windTurbineData',
                        },
                    }
                ]
            },
            {
                path: '/electricalEquipment',
                name: 'electricalEquipment',
                component: () => import('@/pages/commonDataManage/electricalEquipment/index.vue'),
                redirect: '/electricalEquipment/list',
                meta: {
                    title: '电气设备库',
                    showBreadcrumb: true,
                },
                children: [
                    {
                        path: '/electricalEquipment/list',
                        name: 'electricalEquipmentList',
                        component: () => import('@/pages/commonDataManage/electricalEquipment/components/electricalEquipmentList.vue'),
                        meta: {
                            title: '电气设备库列表',
                            showBreadcrumb: true,
                            headerKey: 'dataServiceData',
                            menuKey: 'commonDataManage',
                            activeKey: 'electricalData',
                        },
                    },
                    {
                        path: '/electricalEquipment/detail',
                        name: 'electricalEquipmentDetail',
                        component: () => import('@/pages/commonDataManage/electricalEquipment/components/electricalEquipmentDetail.vue'),
                        meta: {
                            title: '设备详情',
                            showBreadcrumb: true,
                            headerKey: 'dataServiceData',
                            menuKey: 'commonDataManage',
                            activeKey: 'electricalData',
                        },
                    },
                    {
                        path: '/electricalEquipment/edit',
                        name: 'electricalEquipmentHandle',
                        component: () => import('@/pages/commonDataManage/electricalEquipment/components/electricalEquipmentHandle.vue'),
                        meta: {
                            title: '设备管理',
                            showBreadcrumb: true,
                            headerKey: 'dataServiceData',
                            menuKey: 'commonDataManage',
                            activeKey: 'electricalData',
                        },
                    }
                ]
            }, 
            {
                path: '/template',
                name: 'template',
                component: () => import('@/pages/dataService/templateManagement/index.vue'),
                redirect: '/template/list',
                meta: {
                    title: '模板管理',
                    showBreadcrumb: true,
                    headerKey: 'dataServiceData',
                    menuKey: 'projectTemplateManage',
                    activeKey: 'projectTemplate',
                },
                children: [{
                    path: '/template/home',
                    name: 'templateHome',
                    component: () => import('@/pages/dataService/templateManagement/templateHome.vue'),
                    meta: {
                        title: '模板管理',
                        showBreadcrumb: true,
                        headerKey: 'dataServiceData',
                        menuKey: 'projectTemplateManage',
                        activeKey: 'projectTemplate',
                    },
                }, {
                    path: '/template/list',
                    name: 'templateList',
                    component: () => import('@/pages/dataService/templateManagement/templateList.vue'),
                    meta: {
                        title: '模版列表',
                        showBreadcrumb: true,
                        headerKey: 'dataServiceData',
                        menuKey: 'projectTemplateManage',
                        activeKey: 'projectTemplate',
                    },
                }, {
                    path: '/template/list/history',
                    name: 'templateHistoryList',
                    component: () => import('@/pages/dataService/templateManagement/templateHistoryList.vue'),
                    meta: {
                        title: '历史版本',
                        showBreadcrumb: true,
                        headerKey: 'dataServiceData',
                        menuKey: 'projectTemplateManage',
                        activeKey: 'projectTemplate',
                    },
                }
            ]}, {
                path: '/climate',
                name: 'climate',
                component: () => import('@/pages/commonDataManage/climateDataBase/Index.vue'),
                redirect: '/climate/list',
                meta: {
                    title: '气候数据库',
                    showBreadcrumb: true,
                },
                children: [{
                    path: '/climate/list',
                    name: 'climateList',
                    component: () => import('@/pages/commonDataManage/climateDataBase/climateList.vue'),
                    meta: {
                        title: '气候数据库',
                        showBreadcrumb: true,
                        headerKey: 'dataServiceData',
                        menuKey: 'commonDataManage',
                        activeKey: 'climateData',
                        keepAlive: true
                    },
                },{
                    path: '/climate/edit',
                    name: 'climateEdit',
                    component: () => import('@/pages/commonDataManage/climateDataBase/addClimate.vue'),
                    meta: {
                        title: '新增数据',
                        showBreadcrumb: true,
                        headerKey: 'dataServiceData',
                        menuKey: 'commonDataManage',
                        activeKey: 'climateData',
                    },
                },{
                    path: '/climate/detail',
                    name: 'climateDetail',
                    component: () => import('@/pages/commonDataManage/climateDataBase/climateDetail.vue'),
                    meta: {
                        title: '数据详情',
                        showBreadcrumb: true,
                        headerKey: 'dataServiceData',
                        menuKey: 'commonDataManage',
                        activeKey: 'climateData',
                    },
                }]
            }, 
            {
                path: '/waterConservayion',
                name: 'waterConservayionAll',
                component: () => import('@/pages/commonDataManage/waterConservationDatabase/Index.vue'),
                redirect: '/waterConservayion/list',
                meta: {
                    title: '水保数据管理',
                    showBreadcrumb: true,
                },
                children: [
                    {
                        path: '/waterConservayion/list',
                        name: 'waterConservayion',
                        component: () => import('@/pages/commonDataManage/waterConservationDatabase/WaterConservation.vue'),
                        meta: {
                            title: '水保数据库列表',
                            showBreadcrumb: true,
                            headerKey: 'dataServiceData',
                            menuKey: 'commonDataManage',
                            activeKey: 'waterConservayionData',
                            keepAlive: true
                        },
                    },
                    {
                        path: '/waterConservayion/add',
                        name: 'addWaterConservayion',
                        component: () => import('@/pages/commonDataManage/waterConservationDatabase/AddWaterConservation.vue'),
                        meta: {
                            title: '水保数据库新增',
                            showBreadcrumb: true,
                            headerKey: 'dataServiceData',
                            menuKey: 'commonDataManage',
                            activeKey: 'waterConservayionData',
                            keepAlive: true
                        },
                    },
                    {
                        path: '/waterConservayion/detail',
                        name: 'waterConservationDetail',
                        component: () => import('@/pages/commonDataManage/waterConservationDatabase/WaterConservationDetail.vue'),
                        meta: {
                            title: '水保数据库详情',
                            showBreadcrumb: true,
                            headerKey: 'dataServiceData',
                            menuKey: 'commonDataManage',
                            activeKey: 'waterConservayionData',
                            keepAlive: true
                        },
                    }
                ]
            },
            {
                path: '/environment',
                name: 'environment',
                component: () => import('@/pages/commonDataManage/environmentalDatabase/Index.vue'),
                redirect: '/environment/list',
                meta: {
                    title: '环评数据库',
                    showBreadcrumb: true,
                },
                children: [
                    {
                        path: '/environment/list',
                        name: 'environmentDatabase',
                        component: () => import('@/pages/commonDataManage/environmentalDatabase/EnvironmentDatabase.vue'),
                        meta: {
                            title: '环评数据库管理',
                            showBreadcrumb: true,
                            headerKey: 'dataServiceData',
                            menuKey: 'commonDataManage',
                            activeKey: 'environmentData',
                            keepAlive: true
                        }
                    },
                    {
                        path: '/environment/add',
                        name: 'addEnvironment',
                        component: () => import('@/pages/commonDataManage/environmentalDatabase/AddEnvironment.vue'),
                        meta: {
                            title: '新增环评数据',
                            showBreadcrumb: true,
                            headerKey: 'dataServiceData',
                            menuKey: 'commonDataManage',
                            activeKey: 'environmentData',
                            keepAlive: true
                        }
                    },
                    {
                        path: '/environment/detail',
                        name: 'environmentDetail',
                        component: () => import('@/pages/commonDataManage/environmentalDatabase/EnvironmentDetail.vue'),
                        meta: {
                            title: '环评数据详情',
                            showBreadcrumb: true,
                            headerKey: 'dataServiceData',
                            menuKey: 'commonDataManage',
                            activeKey: 'environmentData',
                            keepAlive: true
                        }
                    },

                ]
            },
            {
                path: '/cadDrawwing',
                name: 'cadDrawingManagement',
                component: () => import('@/pages/commonDataManage/cadDrawingManagement/Index.vue'),
                redirect: '/cadDrawwing/home',
                meta: {
                    title: 'CAD制图管理资源',
                    showBreadcrumb: true,
                },
                children:[
                    {
                        path: '/cadDrawwing/home',
                        name: 'CadDrawingManagement',
                        component: () => import('@/pages/commonDataManage/cadDrawingManagement/CadDrawingManagement.vue'),
                        meta: {
                            title: 'CAD制图资源',
                            showBreadcrumb: true,
                            headerKey: 'dataServiceData',
                            menuKey: 'commonDataManage',
                            activeKey: 'cadDrawingData',
                            keepAlive: true
                        },
                    },
                    {
                        path: '/cadDrawwing/list',
                        name: 'CadDrawingManagementList',
                        component: () => import('@/pages/commonDataManage/cadDrawingManagement/CadDrawingManagementList.vue'),
                        meta: {
                            title: 'CAD制图资源列表',
                            showBreadcrumb: true,
                            headerKey: 'dataServiceData',
                            menuKey: 'commonDataManage',
                            activeKey: 'cadDrawingData',
                            keepAlive: true
                        },
                    },
                    {
                        path: '/cadDrawwing/add',
                        name: 'AddCadDrawing',
                        component: () => import('@/pages/commonDataManage/cadDrawingManagement/AddCadDrawing.vue'),
                        meta: {
                            title: '新增CAD制图资源',
                            showBreadcrumb: true,
                            headerKey: 'dataServiceData',
                            menuKey: 'commonDataManage',
                            activeKey: 'cadDrawingData',
                            keepAlive: true
                        },
                    },
                    {
                        path: '/cadDrawwing/detail',
                        name: 'CadDrawingMangagementDetail',
                        component: () => import('@/pages/commonDataManage/cadDrawingManagement/CadDrawingMangagementDetail.vue'),
                        meta: {
                            title: '制图详情',
                            showBreadcrumb: true,
                            headerKey: 'dataServiceData',
                            menuKey: 'commonDataManage',
                            activeKey: 'cadDrawingData',
                            keepAlive: true
                        },
                    },
                ]
            },
            {
                path: '/design',
                name: 'designSpecificationManagement',
                component: () => import('@/pages/commonDataManage/designSpecificationManagement/Index.vue'),
                redirect: '/design/list',
                meta: {
                    title: '设计标准规范管理',
                    showBreadcrumb: true,
                },
                children: [
                    {
                        path: '/design/list',
                        name: 'DesignSpecification',
                        component: () => import('@/pages/commonDataManage/designSpecificationManagement/DesignSpecification.vue'),
                        meta: {
                            title: '设计标准规范管理',
                            showBreadcrumb: true,
                            headerKey: 'dataServiceData',
                            menuKey: 'commonDataManage',
                            activeKey: 'designData',
                            keepAlive: true
                        }
                    },
                    {
                        path: '/design/add',
                        name: 'AddDesignSpecification',
                        component: () => import('@/pages/commonDataManage/designSpecificationManagement/AddDesignSpecification.vue'),
                        meta: {
                            title: '新增制图',
                            showBreadcrumb: true,
                            headerKey: 'dataServiceData',
                            menuKey: 'commonDataManage',
                            activeKey: 'designData',
                            keepAlive: true
                        }
                    },
                    {
                        path: '/design/detail',
                        name: 'DesignSpecificationDetail',
                        component: () => import('@/pages/commonDataManage/designSpecificationManagement/DesignSpecificationDetail.vue'),
                        meta: {
                            title: '制图详情',
                            showBreadcrumb: true,
                            headerKey: 'dataServiceData',
                            menuKey: 'commonDataManage',
                            activeKey: 'designData',
                            keepAlive: true
                        }
                    }
                ]
            },
            {
                path: '/costLibrary',
                name: 'costLibrary',
                component: () => import('@/pages/commonDataManage/costLibrary/index.vue'),
                redirect: '/costLibrary/list',
                meta: {
                    title: '项目造价库',
                    showBreadcrumb: true,
                },
                children: [
                    {
                        path: '/costLibrary/list',
                        name: 'costLibrary',
                        component: () => import('@/pages/commonDataManage/costLibrary/components/costLibraryList.vue'),
                        meta: {
                            title: '项目造价库列表',
                            showBreadcrumb: true,
                            headerKey: 'dataServiceData',
                            menuKey: 'commonDataManage',
                            activeKey: 'costLibrary',
                            keepAlive: true
                        }
                    }
                ]
            },
            {
                path: '/mediumScale',
                name: 'mediumScaleData',
                component: () => import('@/pages/commonDataManage/mediumScale/index.vue'),
                redirect: '/mediumScale/list',
                meta: {
                    title: '中尺度数据库',
                    showBreadcrumb: true,
                },
                children: [
                    {
                        path: '/mediumScale/list',
                        name: 'mediumScaleData',
                        component: () => import('@/pages/commonDataManage/mediumScale/components/mediumScaleList.vue'),
                        meta: {
                            title: '中尺度数据库列表',
                            showBreadcrumb: true,
                            headerKey: 'dataServiceData',
                            menuKey: 'commonDataManage',
                            activeKey: 'mediumScaleData',
                            keepAlive: true
                        }
                    },{
                        path: '/mediumScale/edit',
                        name: 'mediumScaleHandle',
                        component: () => import('@/pages/commonDataManage/mediumScale/components/mediumScaleHandle.vue'),
                        meta: {
                            title: '中尺度数据操作',
                            showBreadcrumb: true,
                            headerKey: 'dataServiceData',
                            menuKey: 'commonDataManage',
                            activeKey: 'mediumScaleData',
                            keepAlive: true
                        }
                    }
                ]
            },
            {
                path: '/staffManage',
                name: 'staffManage',
                component: () => import('@/pages/systemManage/organizationManage/staffManage/index.vue'),
                redirect: '/staffManage/list',
                meta: {
                    title: '人员管理',
                    showBreadcrumb: true,
                },
                children: [
                    {
                        path: '/staffManage/list',
                        name: 'staffManage',
                        component: () => import('@/pages/systemManage/organizationManage/staffManage/components/staffList.vue'),
                        meta: {
                            title: '人员列表',
                            showBreadcrumb: true,
                            headerKey: 'systemSettingsData',
                            menuKey: 'organization',
                            activeKey: 'staffManageList',
                            keepAlive: true
                        }
                    }
                ]
            },
            {
                path: '/departmentManage',
                name: 'departmentManage',
                component: () => import('@/pages/systemManage/organizationManage/departmentManage/index.vue'),
                redirect: '/departmentManage/list',
                meta: {
                    title: '部门管理',
                    showBreadcrumb: true,
                },
                children: [
                    {
                        path: '/departmentManage/list',
                        name: 'departmentDatabase',
                        component: () => import('@/pages/systemManage/organizationManage/departmentManage/components/departmentList.vue'),
                        meta: {
                            title: '部门列表',
                            showBreadcrumb: true,
                            headerKey: 'systemSettingsData',
                            menuKey: 'organization',
                            activeKey: 'departmentManage',
                            keepAlive: true
                        }
                    }
                ]
            },
            {
                path: '/roleManage',
                name: 'roleManage',
                component: () => import('@/pages/systemManage/organizationManage/roleManage/index.vue'),
                redirect: '/roleManage/list',
                meta: {
                    title: '角色管理',
                    showBreadcrumb: true,
                },
                children: [
                    {
                        path: '/roleManage/list',
                        name: 'roleManageList',
                        component: () => import('@/pages/systemManage/organizationManage/roleManage/components/roleList.vue'),
                        meta: {
                            title: '角色列表',
                            showBreadcrumb: true,
                            headerKey: 'systemSettingsData',
                            menuKey: 'organization',
                            activeKey: 'roleManage',
                            keepAlive: true
                        }
                    },{
                        path: '/roleManage/handle',
                        name: 'roleManageHandle',
                        component: () => import('@/pages/systemManage/organizationManage/roleManage/components/roleHandle.vue'),
                        meta: {
                            title: '角色管理',
                            showBreadcrumb: true,
                            headerKey: 'systemSettingsData',
                            menuKey: 'organization',
                            activeKey: 'roleManage',
                            keepAlive: true
                        }
                    }
                ]
            },
            {
                path: '/log',
                name: 'logManage',
                component: () => import('@/pages/systemManage/logManage/index.vue'),
                redirect: '/log/user',
                meta: {
                    title: '用户端',
                },
                children: [
                    {
                        path: '/log/user',
                        name:'userLog',
                        component: () => import('@/pages/systemManage/logManage/userLog/userLog.vue'),
                        meta: {
                            title: '用户端日志',
                            showBreadcrumb: true,
                            headerKey: 'systemSettingsData',
                            menuKey: 'logManage',
                            activeKey: 'userLog',
                            keepAlive: true
                        }
                    }
                ]
            }
        ]
    },
    {
        path: '/operate/login',
        name: 'login',
        component: () => import('@/pages/login/Login.vue'),
    },
    {
        path: '/autoMapResult',
        name: 'autoMapResult',
        component: () => import('@/pages/projectControl/projectResult/TdMapResult.vue'),
        meta: {
            title: 'AI找风资源项目地图结果页',
        },
    },
];